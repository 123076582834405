import { InputType } from '../../../constants/forms';

export const form = {
  title: 'Get Your Quote',
  questions: {
    'First Name': {
      placeholder: 'Enter your first name',
      type: InputType.NAME,
      required: true,
    },
    'Last Name': {
      placeholder: 'Enter your last name',
      type: InputType.NAME,
      required: true,
    },
    Email: {
      placeholder: 'example@email.com',
      type: InputType.EMAIL,
      required: true,
    },
    'Phone Number': {
      placeholder: '(123) 456-7890',
      type: InputType.PHONE,
      required: false,
    },
    'Zip Code': {
      // placeholder: '123 Main St, City, State, ZIP',
      placeholder: 'ZIP',
      type: InputType.ADDRESS,
      required: false,
    },
  },
};
