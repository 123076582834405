import { elements } from '../../../constants/elements';

export const form = {
  details: {
    gridSize: { width: 6, height: 6 },
  },
  setup: [
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 3, y: 4 },
      connecting_side: 1,
    },
    {
      element: elements.tower['5ft'].tower_five_foot,
      position: { x: 4, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'A',
      position: { x: 3, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'C',
      position: { x: 3, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'D',
      position: { x: 2, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'G',
      position: { x: 4, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'F',
      position: { x: 5, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'E',
      position: { x: 4, y: 3 },
      connecting_side: 1,
    },
  ],
};
