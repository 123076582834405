import React, { Fragment, useRef, useState } from 'react';
import './../styles/InputForm.scss';
import { pulseOnce, shake } from '../utils/Utils';
import { InputType } from '../constants/forms';

const InputForm = ({ form, onSubmit, onBack }) => {
  const [responses, setResponses] = useState({});
  const questionsRef = useRef(null);

  const isValidForm = () => {
    const questionElements = [];
    const inputElements = [];
    const questions = form.questions;
    const questionKeys = Object.keys(questions);
    let isValid = true;
    for (let i = 0; i < questionKeys.length; i++) {
      let isValidResponse = true;
      const question = questions[questionKeys[i]];
      const response = responses[questionKeys[i]];
      if (question.required && !response) {
        // Handle invalid required field
        isValidResponse = false;
      }

      // Validate Emails
      if (isValidResponse) {
        const typeIsEmail = question.type === InputType.EMAIL;
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(response);
        const isInvalidEmail = typeIsEmail && !isValidEmail;
        if (
          (question.required && isInvalidEmail) ||
          (isInvalidEmail && response?.length > 0)
        ) {
          // Handle invalid email
          isValidResponse = false;
        }
      }

      // Validate Phone Numbers
      if (isValidResponse) {
        const typeIsPhone = question.type === InputType.PHONE;
        const isValidPhone = response?.length === 14;
        const isInvalidPhone = typeIsPhone && !isValidPhone;
        if (
          (question.required && isInvalidPhone) ||
          (isInvalidPhone && response?.length > 0)
        ) {
          // Handle invalid phone
          isValidResponse = false;
        }
      }

      if (!isValidResponse) {
        isValid = false;
        questionElements.push(
          document.getElementById(`${questionKeys[i]}-${i}`),
        );
        inputElements.push(document.getElementById(`input-${i}`));
      }
    }

    return { isValid, questionElements, inputElements };
  };

  const handleChange = (e, index, type) => {
    e.target.classList.remove('invalid');

    const { value } = e.target;
    let isValid = true;
    let formattedValue = value;

    switch (type) {
      case InputType.NAME:
        isValid = /^[a-zA-Z\s]*$/.test(value); // Allows only letters and spaces
        break;
      case InputType.EMAIL:
        break;
      case InputType.PHONE:
        // Remove non-digit characters for processing
        let digitsOnly = value.replace(/\D/g, '');

        // Format phone number: (XXX) XXX-XXXX
        if (digitsOnly.length <= 3) {
          formattedValue = digitsOnly;
        } else if (digitsOnly.length <= 6) {
          formattedValue = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
        } else {
          formattedValue = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(
            3,
            6,
          )}-${digitsOnly.slice(6, 10)}`;
        }

        // Ensure the formatted value doesn't exceed 14 characters
        isValid = formattedValue.length <= 14; // The formatted length is 13 for (XXX) XXX-XXXX
        break;
      case InputType.ADDRESS:
        isValid = true; // No strict validation for address
        break;
      default:
        break;
    }

    if (isValid) {
      setResponses((prevResponses) => ({
        ...prevResponses,
        [Object.keys(form.questions)[index]]: formattedValue,
      }));
    } else {
      console.log(`Invalid ${type} input:`, value);
    }
  };

  const handleSubmitClick = () => {
    const validForm = isValidForm();
    if (validForm.isValid) {
      pulseOnce('#submit-button', () => {
        onSubmit?.(responses);
      });
    } else {
      shake('#submit-button', () => {
        validForm.inputElements?.forEach((input) => {
          input?.classList.add('invalid');
        });
        if (questionsRef.current) {
          if (validForm.questionElements[0]) {
            // Scroll to the user's selection
            validForm.questionElements[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          } else {
            // Scroll to top on question change
            questionsRef.current.scrollTop = 0;
          }
        }
      });
    }
  };

  const disabledClick = () => {
    shake('#submit-button', () => {
      isValidForm();
    });
  };

  const handleBackClick = () => {
    pulseOnce('#back-button', () => {
      onBack?.();
    });
  };

  return (
    <div className="input-form-content">
      <div className="form-content-container">
        {form.title && <h2 className="title">{form.title}</h2>}
        <div className="questions" ref={questionsRef}>
          {Object.keys(form.questions).map((question, index) => {
            const { placeholder, type, required } = form.questions[question];
            const isRequired = required && !responses[question];
            return (
              <Fragment key={`${question}-${index}`}>
                <h2 className="question" id={`${question}-${index}`}>
                  {question}
                  {isRequired && <span className="required-asterisk">*</span>}
                </h2>
                <input
                  className="input"
                  type="text"
                  name={`input-${index}`}
                  id={`input-${index}`}
                  placeholder={placeholder}
                  value={responses[question] || ''}
                  onChange={(e) => handleChange(e, index, type)}
                />
              </Fragment>
            );
          })}
        </div>
        <div className="form-navigation">
          <div
            className={`submit ${true ? 'disabled' : ''}`}
            type="button"
            id="submit-button"
            onClick={isValidForm() ? handleSubmitClick : disabledClick}
          >
            <h2 className="submit-text">Submit</h2>
          </div>
          <h3
            className="back"
            type="button"
            id="back-button"
            onClick={handleBackClick}
          >
            Back
          </h3>
        </div>
      </div>
    </div>
  );
};

export default InputForm;