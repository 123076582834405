import { elements } from '../../../constants/elements';

export const form = {
  details: {
    towers: 1,
    gridSize: { width: 11, height: 11 },
  },

  setup: [
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 3, y: 6 },
      connecting_side: 1,
    },
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 6, y: 6 },
      connecting_side: 1,
    },
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 9, y: 6 },
      connecting_side: 1,
    },
    {
      text: 'A',
      position: { x: 3, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'B',
      position: { x: 6, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'C',
      position: { x: 9, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'D',
      position: { x: 10, y: 6 },
      connecting_side: 1,
    },
    {
      text: 'E',
      position: { x: 9, y: 7 },
      connecting_side: 1,
    },
    {
      text: 'F',
      position: { x: 6, y: 7 },
      connecting_side: 1,
    },
    {
      text: 'G',
      position: { x: 3, y: 7 },
      connecting_side: 1,
    },
    {
      text: 'H',
      position: { x: 2, y: 6 },
      connecting_side: 1,
    },
    {
      text: 'T1',
      position: { x: 3, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'T2',
      position: { x: 6, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'T3',
      position: { x: 9, y: 3 },
      connecting_side: 1,
    }
  ],
  form: [
    {
      question: 'Connector (T1 to T2)',
      options: {
        ...elements.connector.universal.monkey_bars,
        ...elements.connector.universal.swings,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 5, y: 6 },
      connecting_side: 2,
    },
    {
      question: 'Connector (T2 to T3)',
      options: {
        ...elements.connector.universal.monkey_bars,
        ...elements.connector.universal.swings,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 8, y: 6 },
      connecting_side: 2,
    },
    {
      question: 'Side A',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 3, y: 5 },
      connecting_side: 1,
    },
    {
      question: 'Side H',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment.universal.swings,
        ...elements.attachment['7ft'],
      },
      exclude_options: [

      ],
      position: { x: 2, y: 6 },
      connecting_side: 2,
    },
    {
      question: 'Side G',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 3, y: 7 },
      connecting_side: 3,
    },
    {
      question: 'Side B',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 6, y: 5 },
      connecting_side: 1,
    },
    {
      question: 'Side F',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 6, y: 7 },
      connecting_side: 3,
    },
    {
      question: 'Side C',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 9, y: 5 },
      connecting_side: 1,
    },
    {
      question: 'Side D',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment.universal.swings,
        ...elements.attachment['7ft'],
      },
      exclude_options: [

      ],
      position: { x: 10, y: 6 },
      connecting_side: 4,
    },
    {
      question: 'Side E',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 9, y: 7 },
      connecting_side: 3,
    },
    {
      question: 'Choose Roof Type',
      options: { ...elements.roofs['3 Towers'] },
      exclude_options: [],
      position: null,
    },    
    {
      question: 'Choose Tower Add-Ons',
      options: { ...elements.add_ons['7ft'], ...elements.add_ons.universal },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Include Mist System?',
      options: elements.mist_system.one_tower,
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Accent Color',
      options: {
        ...elements.accent_colors.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (1 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (2 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (3 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Tower 1 Base',
      options: {
        ...elements.tower_base['7ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },
    {
      question: 'Choose Tower 2 Base',
      options: {
        ...elements.tower_base['7ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },
    {
      question: 'Choose Tower 3 Base',
      options: {
        ...elements.tower_base['7ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },
  ],
}
