import axios from 'axios';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FormPreview from './FormPreview';
import FormContent from './FormContent';
import './../styles/FormContainer.scss';
import { prefixFormQuestions, formConfigs } from '../constants/forms';
import { form as suffixForm } from '../assets/froms/suffix/suffix_1.js';
import { formatCurrency, pulseOnce, shake } from '../utils/Utils';
import InputForm from './InputForm';

const FormContainer = () => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selections, setSelections] = useState({});
  const [rotation, setRotation] = useState(0);
  const imageCanvasRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmitClick = async (suffixData) => {
    // Start spinner
    setLoading(true);

    // Submit Logic
    try {
      const submissionData = await generateSubmissionData(suffixData);

      // Use for CRM endpoint
      const ghlBaseUrl = process.env.REACT_APP_CRM_API_URL;
      const ghlKey = process.env.REACT_APP_CRM_API_KEY;
      const ghlUrl = `${ghlBaseUrl}/${ghlKey}`;

      // Mimic JSON body from Gravity Forms (phase 1 transition)
      const webhookTemplate = {
        2: submissionData.userData.Email,
        3: submissionData.userData['Phone Number'],
        11: internalFacingDescription(submissionData),
        12: submissionData.configuration.playset_details.cost.replace('$', ''),
        15: submissionData.userData['Last Name'],
        16: submissionData.userData['First Name'],
        18: submissionData.userData['Zip Code'],
        20: submissionData.utmSource,
        21: submissionData.utmMedium,
        22: submissionData.utmCampaign,
        23: submissionData.utmGclid,
        24: submissionData.utmContent,
        25: submissionData.utmTerm,
        26: submissionData.utmDclid,

        date_created: submissionData.timeStamp,
        source_url: window.location.href,
      };

      // Make axios POST to CRM with submissionData
      const response = await axios.post(ghlUrl, webhookTemplate, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // // Handle successful response
      // console.log('CRM response:', response.data);

      // Send email to customer
      await sendCustomerEmail(submissionData);

      // Stop spinner then redirect to thank you page
      setLoading(false); // Stop spinner
      window.location.href =
        'https://www.ruffhouseplaysystems.com/builder-thank-you';
    } catch (error) {
      // Handle submission error
      console.error(
        'Error submitting data',
        error.response?.data || error.message,
      );
      setLoading(false); // Stop spinner
    }
  };

  const sendCustomerEmail = async (submissionData) => {
    // Flask endpoint
    const flaskUrl = process.env.REACT_APP_FLASK_API_URL;
    const flaskKey = process.env.REACT_APP_FLASK_API_KEY;

    const { playset_details } = submissionData.configuration;

    const payload = {
      to: submissionData.userData.Email,
      first_name: submissionData.userData['First Name'],
      last_name: submissionData.userData['Last Name'],
      subject: 'Vinyl Play System Estimate',
      cost: playset_details.cost,
      dimensions: `${playset_details.dimensions.height} x ${playset_details.dimensions.width}`,
      customer_facing_selections: customerFacingDescription(submissionData),
      internal_facing_selections: internalFacingDescription(submissionData),
      attachments: [
        {
          content: submissionData.configuration.playset_details.image, // Needs to be base64 encoded
          filename: 'playset.png',
        },
      ],
    };

    try {
      // Make axios POST to Flask with submissionData
      const response = await axios.post(flaskUrl, payload, {
        headers: {
          Token: flaskKey,
          'Content-Type': 'application/json',
        },
      });
      // console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Sending email:', error);
    }
  };

  const generateSubmissionData = async (userData) => {
    const questions = [
      ...prefixFormQuestions,
      ...getBaseFormConfig().form.map((item) => item.question),
    ];
    const { height, width, cost } = selectionInfo;

    // Get the image from the canvas and convert it to base64 string so we can send it to Flask
    const imageBlob = await imageCanvasRef.current.getCanvasBlob();
    const imageBase64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(imageBlob);
    });

    const configuration = {
      selections: Object.keys(selections).reduce((acc, key) => {
        const newKey = questions[parseInt(key, 10)]; // Replace index with string
        acc[newKey] = selections[key];
        return acc;
      }, {}),
      playset_details: {
        cost: formatCurrency(cost),
        dimensions: {
          height: `${height || 0} ft`,
          width: `${width || 0} ft`,
        },
        rotation: `${rotation * 90}deg`,
        image: imageBase64, // NOTE, sending in encoded image, not the Blob
      },
    };
    const timeStamp = Date();
    const id = crypto.randomUUID();

    // ~~~~~Get UTM Codes via Cookie~~~~~
    // --Sample cookie--
    // Name: __utmzz
    // Value: utmcsr=google|utmcmd=organic|utmccn=(not set)|utmctr=(not provided)
    // console.log('Document cookies:', document.cookie);

    const utmCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('__utmzz='));
    // console.log('UTM Cookie:', utmCookie);
    if (utmCookie) {
      const utmValue = utmCookie.replace('__utmzz=', '');
      // console.log('UTM Value:', utmValue);

      const utmParamsArray = utmValue.split('|');
      // console.log('UTM Params Array:', utmParamsArray);

      const utmParams = utmParamsArray.reduce((acc, param) => {
        const [key, value] = param.split('=');
        // console.log(`Parsing param: ${param}, key: ${key}, value: ${value}`);
        acc[key] = value;
        return acc;
      }, {});

      // console.log('UTM Params:', utmParams);

      const utmSource = utmParams.utmcsr || 'None'; // i.e. Google
      const utmMedium = utmParams.utmcmd || 'None'; // i.e. Organic
      const utmCampaign = utmParams.utmccn || 'None'; // i.e. Bio, (not set)
      const utmContent = utmParams.utmcct || 'None'; // i.e. Profile
      const utmTerm = utmParams.utmctr || 'None'; // i.e. (not provided)
      const utmGclid = utmParams.utmgclid || 'None'; // Google Click Identifier, i.e. undefined
      const utmDclid = utmParams.utmdclid || 'None'; // DoubleClick Identifier

      // console.log('UTM Source:', utmSource);
      // console.log('UTM Medium:', utmMedium);
      // console.log('UTM Campaign:', utmCampaign);
      // console.log('UTM Content:', utmContent);
      // console.log('UTM Term:', utmTerm);
      // console.log('UTM GCLID:', utmGclid);
      // console.log('UTM DCLID:', utmDclid);

      return {
        configuration,
        userData,
        timeStamp,
        id,
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        utmTerm,
        utmGclid,
        utmDclid,
      };
    } else {
      console.log('UTM Cookie not found');
      return {
        configuration,
        userData,
        timeStamp,
        id,
        utmSource: 'None',
        utmMedium: 'None',
        utmCampaign: 'None',
        utmContent: 'None',
        utmTerm: 'None',
        utmGclid: 'None',
        utmDclid: 'None',
      };
    }
  };

  // const getSubmissionDataAsFormData = (submissionData) => {
  //   // Create FormData object
  //   const formData = new FormData();

  //   // Append non-file data
  //   formData.append(
  //     'configuration',
  //     JSON.stringify(submissionData.configuration),
  //   );
  //   formData.append('userData', JSON.stringify(submissionData.userData));
  //   formData.append('timeStamp', submissionData.timeStamp);
  //   formData.append('utmCode', submissionData.utmCode);
  //   formData.append('id', submissionData.id);

  //   // Append image (as Blob or base64, depending on your backend)
  //   formData.append(
  //     'image',
  //     submissionData.configuration.playset_details.image,
  //   );
  //   return formData;
  // };

  const internalFacingDescription = (submissionData) => {
    const { selections, playset_details } = submissionData.configuration;
    const selectionEntries = Object.entries(selections)
      .map(([key, value]) => `- ${key}: ${value}`)
      .join('\r\n');
    const utmCodes = `- Source: ${submissionData.utmSource}\r\n- Medium: ${submissionData.utmMedium}\r\n- Campaign: ${submissionData.utmCampaign}\r\n- GCLID: ${submissionData.utmGclid}\r\n- Content: ${submissionData.utmContent}\r\n- Term: ${submissionData.utmTerm}\r\n- DCLID: ${submissionData.utmDclid}`;

    return `Playset Details:\r\n- Cost: ${playset_details.cost}\r\n- Dimensions: ${playset_details.dimensions.height} x ${playset_details.dimensions.width}\r\n\r\nSelections:\r\n${selectionEntries}\r\n\r\nUTM Codes:\r\n${utmCodes}\r\n\r\nRef: ${submissionData.id}`;
  };

  const customerFacingDescription = (submissionData) => {
    const { selections } = submissionData.configuration;
    const selectionEntries = Object.entries(selections)
      .map(([key, value]) => `- ${key}: ${value}`)
      .join('\r\n');

    return `${selectionEntries}\r\n\r\nReference Number: ${submissionData.id}`;
  };

  const getBaseFormConfig = useCallback(() => {
    let form = { ...formConfigs }.options;
    for (let i = 0; i < questionIndex && i < prefixFormQuestions.length; i++) {
      const configKey = selections[i];
      form = form[configKey].options || form[configKey];
    }
    return form.form ? form : undefined;
  }, [questionIndex, selections]);

  const getFormIndex = useCallback(() => {
    if (questionIndex < prefixFormQuestions.length) {
      return 0;
    } else {
      const length = Object.keys(getBaseFormConfig().form).length;
      if (questionIndex - prefixFormQuestions.length < length) {
        return 1;
      } else {
        return 2;
      }
    }
  }, [getBaseFormConfig, questionIndex]);

  const getDisplayForm = useCallback(() => {
    switch (getFormIndex()) {
      case 0:
      default:
        const selectionsLength = Object.keys(selections).length;
        if (
          questionIndex < prefixFormQuestions.length &&
          selectionsLength >= prefixFormQuestions.length
        ) {
          let form = { ...formConfigs }.options;
          for (
            let i = 0;
            i < selectionsLength && i < prefixFormQuestions.length;
            i++
          ) {
            const configKey = selections[i];
            form = form[configKey].options || form[configKey];
          }
          return form;
        }
        let form = { ...formConfigs };
        for (
          let i = 0;
          i < selectionsLength && i < prefixFormQuestions.length;
          i++
        ) {
          const configKey = selections[i];
          form = form.options[configKey];
        }
        return form.form || getBaseFormConfig();
      case 1:
      case 2:
        return getBaseFormConfig();
    }
  }, [getBaseFormConfig, getFormIndex, selections, questionIndex]);

  const getOffsetQuestionIndex = () => {
    switch (getFormIndex()) {
      case 0:
      default:
        return questionIndex;
      case 1:
        return questionIndex - prefixFormQuestions.length;
      case 2:
        return (
          questionIndex -
          prefixFormQuestions.length -
          Object.keys(getBaseFormConfig().form).length
        );
    }
  };

  const getCurrentQuestion = () => {
    switch (getFormIndex()) {
      case 0:
      default:
        return prefixFormQuestions[getOffsetQuestionIndex()];
      case 1:
        return getBaseFormConfig().form[getOffsetQuestionIndex()].question;
      case 2:
        return 'EMPTY QUESTION';
    }
  };

  const getCurrentOptions = () => {
    let options;
    switch (getFormIndex()) {
      case 0:
        let form = { ...formConfigs }.options;
        for (let i = 0; i < questionIndex; i++) {
          const configKey = selections[i];
          form = form[configKey].options || form[configKey];
        }
        options = Object.keys(form);
        break;
      case 1:
        options = Object.values(
          getBaseFormConfig().form[getOffsetQuestionIndex()].options,
        ).map((option) => option.name);

        const exclusions = new Set(
          getBaseFormConfig().form[
            getOffsetQuestionIndex()
          ].exclude_options.map((option) => option.name),
        );

        options = options.filter((option) => !exclusions.has(option));
        break;
      default:
        options = ['EMPTY', 'EMPTY', 'EMPTY', 'EMPTY'];
        break;
    }

    let newSelections = { ...selections };

    if (options.length === 1) {
      newSelections = {
        ...selections,
        [questionIndex]: options[0],
      };
      if (selections[questionIndex]) {
        setQuestionIndex(Math.max(questionIndex - 1, 0));
      } else {
        setQuestionIndex(questionIndex + 1);
      }
    }

    newSelections = Object.fromEntries(
      Object.entries(newSelections).filter(
        ([key]) => Number(key) <= questionIndex,
      ),
    );

    if (Object.keys(newSelections).length !== Object.keys(selections).length) {
      setSelections(newSelections);
    }
    return options;
  };

  const selectionInfo = useMemo(() => {
    const formConfig = getDisplayForm();
    if (!formConfig) return;

    const prefixOffset = prefixFormQuestions.length;

    const questionsToProcess =
      formConfig.form?.slice(0, questionIndex - prefixOffset + 1) || [];

    const isNeighborPresent = (elements, x, y) =>
      elements.some(
        (e) => !!e.element && e.position.x === x && e.position.y === y,
      );

    const filterSetupToProcess = (setup) =>
      setup.filter((element) => {
        if (!!element.element) return true;

        const { x, y } = element.position;

        const hasHorizontalNeighbors =
          isNeighborPresent(setup, x - 1, y) &&
          isNeighborPresent(setup, x + 1, y);

        const hasVerticalNeighbors =
          isNeighborPresent(setup, x, y - 1) &&
          isNeighborPresent(setup, x, y + 1);

        return hasHorizontalNeighbors || hasVerticalNeighbors;
      });

    const setupToProcess = filterSetupToProcess(formConfig.setup || []);

    let selectedElements = [];
    let cost = 0;

    const addCostAndElement = (
      element,
      dimensions,
      position,
      connectingSide,
    ) => {
      cost += element?.price || 0;
      if (position && connectingSide && dimensions) {
        selectedElements.push({
          ...element,
          position,
          dimensions,
          connecting_side: connectingSide,
        });
      }
    };

    const processQuestions = (questions) => {
      questions.forEach((question, i) => {
        const optionName = selections[i + prefixOffset];
        const option = Object.values(question.options).find(
          (opt) => opt.name === optionName,
        );

        addCostAndElement(
          option,
          option?.dimensions,
          question.position,
          question.connecting_side,
        );
      });
    };

    const processSetup = (setup) => {
      setup.forEach((element) => {
        addCostAndElement(
          element.element,
          element.element?.dimensions || { length: 5, width: 5 },
          element.position,
          element.connecting_side,
        );
      });
    };

    processQuestions(questionsToProcess);
    processSetup(setupToProcess);

    const calculateDimension = (elements, axis, sizeProp) => {
      const _elements = elements.map((element) => {
        const e = { ...element, dimensions: { ...element.dimensions } };
        if ([1, 3].includes(element.connecting_side)) {
          e.dimensions.length = element.dimensions.width;
          e.dimensions.width = element.dimensions.length;
        }
        return e;
      });

      const towerElements = _elements.filter((element) =>
        element.name.includes('Tower'),
      );

      const seenPositions = new Set();
      const towers = towerElements
        .filter((tower) => {
          const pos = tower.position[axis];
          if (seenPositions.has(pos)) {
            return false;
          } else {
            seenPositions.add(pos);
            return true;
          }
        })
        .sort((a, b) => {
          return a.position[axis] - b.position[axis];
        });
      const towerPositions = Array.from(
        new Set(towers.map((tower) => tower.position[axis])),
      );

      const startTowerPosition = towerPositions[0];
      const endTowerPosition = towerPositions[towerPositions.length - 1];
      const towerDimensions = towers[0].dimensions[sizeProp];
      let towerSize;
      if (startTowerPosition === endTowerPosition) {
        towerSize = towerDimensions;
      } else if (startTowerPosition === 0) {
        towerSize = endTowerPosition * towerDimensions;
      } else {
        towerSize =
          (endTowerPosition - startTowerPosition + 1) * towerDimensions;
      }

      const getDimensionBiggestElement = (dimension) => {
        const dimensionElements = _elements
          .filter((element) => element.position[axis] === dimension)
          .sort((a, b) => b.dimensions[sizeProp] - a.dimensions[sizeProp]);
        return dimensionElements[0];
      };

      const startTowerBiggestElemement = getDimensionBiggestElement(
        towerPositions[0],
      );
      const endTowerBiggestElemement = getDimensionBiggestElement(
        towerPositions[towerPositions.length - 1],
      );

      let startElementSize =
        getDimensionBiggestElement(towerPositions[0] - 1)?.dimensions[
          sizeProp
        ] || 0;
      let endElementSize =
        getDimensionBiggestElement(
          towerPositions[towerPositions.length - 1] + 1,
        )?.dimensions[sizeProp] || 0;

      if (
        startElementSize === 0 &&
        startTowerBiggestElemement &&
        !startTowerBiggestElemement.name.includes('Tower')
      ) {
        startElementSize = Math.max(
          (startTowerBiggestElemement.dimensions[sizeProp] -
            towers[0].dimensions[sizeProp]) /
            2,
          0,
        );
      }

      if (
        endElementSize === 0 &&
        endTowerBiggestElemement &&
        !endTowerBiggestElemement.name.includes('Tower')
      ) {
        endElementSize = Math.max(
          (endTowerBiggestElemement.dimensions[sizeProp] -
            towers[towers.length - 1].dimensions[sizeProp]) /
            2,
          0,
        );
      }

      const endsWidths = startElementSize + endElementSize;

      return endsWidths + towerSize;
    };

    if (!selectedElements.length) {
      return { width: 0, height: 0, cost };
    }

    const width = calculateDimension(selectedElements, 'x', 'width');
    const height = calculateDimension(selectedElements, 'y', 'length');

    if (rotation === 1 || rotation === 3) {
      return { width: height, height: width, cost };
    }
    return { width, height, cost };
  }, [getDisplayForm, questionIndex, selections, rotation]);

  useEffect(() => {
    const formConfig = getDisplayForm();

    if (!imageCanvasRef.current) {
      return;
    }

    if (!formConfig) {
      imageCanvasRef.current.drawGrid();
      imageCanvasRef.current.updateCanvasPreview();
      return;
    }

    imageCanvasRef.current.updatePreviewSelections(
      prefixFormQuestions,
      questionIndex,
      formConfig,
      selections,
      rotation,
    );
  }, [selections, questionIndex, getDisplayForm, rotation]);

  return (
    <div className="form-container">
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="form-wrapper">
        <FormPreview
          ref={imageCanvasRef}
          height={selectionInfo?.height}
          width={selectionInfo?.width}
          cost={selectionInfo?.cost}
          chunkWidth={getDisplayForm()?.details?.gridSize.width}
          chunkHeight={getDisplayForm()?.details?.gridSize.height}
          onRotate={() => setRotation((rotation + 1) % 4)}
        />
        {getFormIndex() < 2 && (
          <FormContent
            key={questionIndex}
            question={getCurrentQuestion()}
            options={getCurrentOptions()}
            selection={selections[questionIndex]}
            onOptionClick={(option, index) => {
              const newSelections = { ...selections, [questionIndex]: option };
              setSelections(newSelections);
            }}
            onPrevious={() => {
              if (questionIndex === 0) {
                shake('#previous-button', () => {});
                return;
              }
              pulseOnce('#previous-button', () => {
                setQuestionIndex(Math.max(questionIndex - 1, 0));
              });
            }}
            onNext={() => {
              setQuestionIndex(questionIndex + 1);
            }}
          />
        )}
        {getFormIndex() >= 2 && (
          <InputForm
            form={suffixForm}
            onSubmit={handleSubmitClick}
            onBack={() => {
              if (questionIndex === 0) {
                shake('#back-button', () => {});
                return;
              }
              pulseOnce('#back-button', () => {
                setQuestionIndex(Math.max(questionIndex - 1, 0));
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FormContainer;
