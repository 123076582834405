// 'Half Tower Layout' -> Selected None

import { elements } from '../../../constants/elements';

export const form = {
  details: {
    gridSize: { width: 5, height: 5 },
  },
  setup: [
    {
      element: elements.tower['5ft'].tower_five_foot,
      position: { x: 3, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'A',
      position: { x: 3, y: 2 },
      connecting_side: 1,
    },
    {
      text: 'B',
      position: { x: 4, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'C',
      position: { x: 3, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'D',
      position: { x: 2, y: 3 },
      connecting_side: 1,
    },
  ],
  form: [
    {
      question: 'Side A',
      options: {
        ...elements.attachment.universal.default,

        ...elements.attachment['5ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 3, y: 2 },
      connecting_side: 1,
    },
    {
      question: 'Side B',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment.universal.swings,
        ...elements.attachment['5ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 4, y: 3 },
      connecting_side: 4,
    },
    {
      question: 'Side C',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['5ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 3, y: 4 },
      connecting_side: 3,
    },
    {
      question: 'Side D',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['5ft'],
      },
      exclude_options: [],
      position: { x: 2, y: 3 },
      connecting_side: 2,
    },
    {
      question: 'Choose Roof Type',
      options: { ...elements.roofs['1 Tower'] },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Tower Add-Ons',
      options: { ...elements.add_ons['5ft'], ...elements.add_ons.universal },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Include Mist System?',
      options: elements.mist_system.one_tower,
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Accent Color',
      options: {
        ...elements.accent_colors.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (1 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (2 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (3 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Tower Base',
      options: {
        ...elements.tower_base.universal,
      },
      exclude_options: [],
      position: null,
    },
  ],
};
