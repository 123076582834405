import { elements } from '../../../constants/elements';

export const form = {
  details: {
    towers: 1,
    gridSize: { width: 10, height: 10 },
  },
  setup: [
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 7, y: 4 },
      connecting_side: 1,
    },
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 4, y: 7 },
      connecting_side: 1,
    },
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 7, y: 7 },
      connecting_side: 1,
    },
    {
      element: elements.tower['5ft'].tower_five_foot,
      position: { x: 6, y: 4 },
      connecting_side: 1,
    },
    // {
    //   text: 'A',
    //   position: { x: 6, y: 4 },
    //   connecting_side: 1,
    // },
    {
      text: 'B',
      position: { x: 7, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'C',
      position: { x: 8, y: 4 },
      connecting_side: 1,
    },
    // {
    //   text: 'D',
    //   position: { x: 4, y: 5 },
    //   connecting_side: 1,
    // },
    {
      text: 'E',
      position: { x: 8, y: 7 },
      connecting_side: 1,
    },
    {
      text: 'F',
      position: { x: 7, y: 8 },
      connecting_side: 1,
    },
    // {
    //   text: 'G',
    //   position: { x: 5, y: 6 },
    //   connecting_side: 1,
    // },
    {
      text: 'H',
      position: { x: 4, y: 6 },
      connecting_side: 1,
    },
    {
      text: 'I',
      position: { x: 3, y: 7 },
      connecting_side: 1,
    },
    {
      text: 'J',
      position: { x: 4, y: 8 },
      connecting_side: 1,
    },
    {
      text: '1.5K',
      position: { x: 6, y: 3 },
      connecting_side: 1,
    },
    {
      text: '1.5L',
      position: { x: 5, y: 4 },
      connecting_side: 1,
    },
    // {
    //   text: '1.5M',
    //   position: { x: 6, y: 5 },
    //   connecting_side: 1,
    // },
    {
      text: 'T1',
      position: { x: 7, y: 1 },
      connecting_side: 1,
    },
    {
      text: 'T2',
      position: { x: 7, y: 10 },
      connecting_side: 1,
    },
    {
      text: 'T3',
      position: { x: 4, y: 10 },
      connecting_side: 1,
    },
  ],
  form: [

    {
      question: 'Connector (T1 to T2)',
      options: {
        //...elements.connector.universal.monkey_bars,
        ...elements.connector.universal.swings,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 7, y: 6 },
      connecting_side: 1,
    },
    {
      question: 'Connector (T2 to T3)',
      options: {
        ...elements.connector.universal.monkey_bars,
        // ...elements.connector.universal.swings,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 5, y: 7 },
      connecting_side: 4,
    },
    // {
    //   question: 'Side A',
    //   options: {
    //     ...elements.attachment['7ft'],
    //     ...elements.attachment.universal.default,
    //   },
    //   exclude_options: [],
    //   position: { x: 6, y: 4 },
    //   connecting_side: 2,
    // },
    {
      question: 'Side B',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 7, y: 3 },
      connecting_side: 1,
    },
    {
      question: 'Side C',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 8, y: 4 },
      connecting_side: 4,
    },
    {
      question: 'Half Tower Side K',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['5ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
        elements.attachment['5ft'].rock_wall,
        elements.attachment['5ft'].rope_ladder,
        elements.attachment['5ft'].vinyl_ramp,
        elements.attachment['7ft'].rock_wall,
        elements.attachment['7ft'].rope_ladder,
      ],
      position: { x: 6, y: 3 },
      connecting_side: 1,
    },
    {
      question: 'Half Tower Side L',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['5ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 5, y: 4 },
      connecting_side: 2,
    },
    // {
    //   question: 'Half Tower Side M',
    //   options: {
    //     ...elements.attachment.universal.default,
    //     ...elements.attachment['5ft'],
    //   },
    //   exclude_options: [
    //     elements.attachment['5ft'].tube_slide,
    //     elements.attachment['7ft'].tube_slide,
    //   ],
    //   position: { x: 6, y: 5 },
    //   connecting_side: 3,
    // },
    {
      question: 'Side E',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 8, y: 7 },
      connecting_side: 4,
    },
    {
      question: 'Side F',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment.universal.swings,
        ...elements.attachment['7ft'],
      },
      exclude_options: [

      ],
      position: { x: 7, y: 8 },
      connecting_side: 3,
    },
    {
      question: 'Side H',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
        elements.attachment['5ft'].wave_slide,
        elements.attachment['7ft'].scoop_slide,
      ],
      position: { x: 4, y: 6 },
      connecting_side: 1,
    },
    {
      question: 'Side I',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [

      ],
      position: { x: 3, y: 7 },
      connecting_side: 2,
    },
    {
      question: 'Side J',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [

      ],
      position: { x: 4, y: 8 },
      connecting_side: 3,
    },
    {
      question: 'Choose Roof Type',
      options: { ...elements.roofs['3 Towers'] },
      exclude_options: [],
      position: null,
    },    
    {
      question: 'Choose Tower Add-Ons',
      options: { ...elements.add_ons['7ft'], ...elements.add_ons.universal },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Include Mist System?',
      options: elements.mist_system.one_tower,
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Accent Color',
      options: {
        ...elements.accent_colors.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (1 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (2 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (3 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Tower 1 Base',
      options: {
        ...elements.tower_base['7ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },
    {
      question: 'Choose Half Tower Base',
      options: {
        ...elements.tower_base['5ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },

    {
      question: 'Choose Tower 2 Base',
      options: {
        ...elements.tower_base['7ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },
    {
      question: 'Choose Tower 3 Base',
      options: {
        ...elements.tower_base['7ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },
  ],
};
