// 'Number of Towers' -> Selected 2 Towers ('Height of Towers' -> No Selection)

import { elements } from '../../../constants/elements';

export const form = {
  details: {
    gridSize: { width: 10, height: 10 },
  },
  setup: [
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 4, y: 5 },
      connecting_side: 1,
    },
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 7, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'A',
      position: { x: 4, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'T1',
      position: { x: 4, y: 8 },
      connecting_side: 1,
    },
    {
      text: 'T2',
      position: { x: 7, y: 8 },
      connecting_side: 1,
    },
    {
      text: 'C',
      position: { x: 4, y: 6 },
      connecting_side: 1,
    },
    {
      text: 'D',
      position: { x: 3, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'E',
      position: { x: 7, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'F',
      position: { x: 8, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'G',
      position: { x: 7, y: 6 },
      connecting_side: 1,
    },
  ],
};
