import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { getCSSVariable } from '../utils/Utils';
import './../styles/ImageCanvas.scss';

const ImageCanvas = forwardRef(
  ({ chunkWidth, chunkHeight, chunkSize }, ref) => {
    const canvasRef = useRef(null);
    const offscreenCanvasRef = useRef(null); // Offscreen canvas reference
    const backgroundColor = getCSSVariable('--secondary-color');
    const textColor = getCSSVariable('--primary-color');
    const strokeColor = 'rgba(0, 0, 0, 0.25)';
    const lineWidth = 1;
    const textScale = 0.8;

    const clearImage = () => {
      const offscreenCanvas = offscreenCanvasRef.current;
      if (!offscreenCanvas) return;
      const ctx = offscreenCanvas.getContext('2d');
      ctx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
    };

    const drawGrid = useCallback(
      (rotation) => {
        const offscreenCanvas = offscreenCanvasRef.current;
        if (!offscreenCanvas) return;

        const ctx = offscreenCanvas.getContext('2d');
        if (!ctx) return;
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);

        // Set line styles for grid
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = lineWidth;

        let firstPassWidth = chunkWidth;
        let secondPassWidth = chunkHeight;
        if (rotation === 1 || rotation === 3) {
          firstPassWidth = chunkHeight;
          secondPassWidth = chunkWidth;
        }

        // Draw first pass lines
        for (let x = 1; x < firstPassWidth; x++) {
          const xPos = x * chunkSize;
          ctx.beginPath();
          ctx.moveTo(xPos, 0);
          ctx.lineTo(xPos, secondPassWidth * chunkSize);
          ctx.stroke();
        }

        // Draw second pass lines
        for (let y = 1; y < secondPassWidth; y++) {
          const yPos = y * chunkSize;
          ctx.beginPath();
          ctx.moveTo(0, yPos);
          ctx.lineTo(firstPassWidth * chunkSize, yPos);
          ctx.stroke();
        }
      },
      [backgroundColor, chunkWidth, chunkHeight, chunkSize],
    );

    const drawAtChunk = (x, y, drawingFunc, callback, connectingSide) => {
      const offscreenCanvas = offscreenCanvasRef.current;
      if (!offscreenCanvas) return;
      const ctx = offscreenCanvas.getContext('2d');

      const chunkX = (x - 1) * chunkSize;
      const chunkY = (y - 1) * chunkSize;

      const rotationAngle =
        {
          1: 0,
          2: -Math.PI / 2,
          3: Math.PI,
          4: Math.PI / 2,
        }[connectingSide] || 0;

      const centerX = chunkX + chunkSize / 2;
      const centerY = chunkY + chunkSize / 2;

      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(rotationAngle);

      // Call the specific drawing function (either image or text)
      drawingFunc(ctx, chunkSize);

      ctx.restore();

      // Call the callback once the drawing is finished
      if (callback) callback();
    };

    const drawImageAtChunk = (
      x,
      y,
      imageSrc,
      connectingSide,
      spanChunkWidth,
      spanChunkHeight,
      callback,
    ) => {
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
        drawAtChunk(
          x,
          y,
          (ctx, chunkSize) => {
            const scale = Math.min(
              (spanChunkWidth * chunkSize) / image.width,
              (spanChunkHeight * chunkSize) / image.height,
            );
            const scaledWidth =
              spanChunkWidth || spanChunkHeight > 1
                ? image.width * ((spanChunkWidth * chunkSize) / image.width)
                : scale;
            const scaledHeight =
              spanChunkWidth || spanChunkHeight > 1
                ? image.height * ((spanChunkHeight * chunkSize) / image.height)
                : scale;

            // Draw the image on top of the background
            ctx.drawImage(
              image,
              // -(scaledWidth / 2) + ((spanChunkWidth - 1) * chunkSize) / 2,
              -(scaledWidth / 2),
              -(scaledHeight / 2) - ((spanChunkHeight - 1) * chunkSize) / 2,
              scaledWidth,
              scaledHeight,
            );
          },
          callback,
          connectingSide,
        );
      };
    };

    const drawTextAtChunk = (x, y, text, connectingSide, callback) => {
      drawAtChunk(
        x,
        y,
        (ctx, chunkSize) => {
          const maxSize = chunkSize * textScale;
          const baseFontSize = 20;
          ctx.font = `${baseFontSize}px Arial`;
          const textWidth = ctx.measureText(text).width;
          const textHeight = baseFontSize;

          const scaleByWidth = maxSize / textWidth;
          const scaleByHeight = maxSize / textHeight;
          const scale = Math.min(scaleByWidth, scaleByHeight);

          ctx.font = `${baseFontSize * scale}px Arial`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = textColor;
          ctx.fillText(text, 0, 0);
        },
        callback,
        connectingSide,
      );
    };

    const updateCanvasPreview = (rotation = 0) => {
      const canvas = canvasRef.current;
      const offscreenCanvas = offscreenCanvasRef.current;
      if (!canvas || !offscreenCanvas) return;
      const ctx = canvas.getContext('2d');
      // Apply rotation based on the variable value
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      switch (rotation) {
        case 0: // No rotation
        default:
          break;
        case 1: // 90 degrees
          ctx.translate(canvas.width, 0);
          ctx.rotate((90 * Math.PI) / 180);
          break;
        case 2: // 180 degrees
          ctx.translate(canvas.width, canvas.height);
          ctx.rotate((180 * Math.PI) / 180);
          break;
        case 3: // 270 degrees
          ctx.translate(0, canvas.height);
          ctx.rotate((270 * Math.PI) / 180);
          break;
      }
      ctx.drawImage(offscreenCanvas, 0, 0);
      ctx.restore();
    };

    const updatePreviewSelections = (
      prefixFormQuestions,
      questionIndex,
      formConfig,
      selections,
      rotation,
    ) => {
      if (!canvasRef || !offscreenCanvasRef.current) {
        return;
      }

      if (rotation === 1 || rotation === 3) {
        offscreenCanvasRef.current.width = chunkHeight * chunkSize;
        offscreenCanvasRef.current.height = chunkWidth * chunkSize;
      } else {
        offscreenCanvasRef.current.width = chunkWidth * chunkSize;
        offscreenCanvasRef.current.height = chunkHeight * chunkSize;
      }

      drawGrid(rotation);

      let totalChunks = 0;
      let completedChunks = 0;
      let selectionPositions = [];

      // Track when all chunks are drawn
      const onChunkDrawn = (callback) => {
        completedChunks++;
        if (completedChunks === totalChunks) callback();
      };

      const handleSecondPass = () => {
        const setup = formConfig.setup;
        totalChunks += setup.length;

        setup.forEach(({ position, element, text, connecting_side }) => {
          if (
            selectionPositions.some(
              ({ x, y }) => x === position.x && y === position.y,
            )
          ) {
            onChunkDrawn(() => {
              updateCanvasPreview(rotation);
            });
            return;
          }

          if (element) {
            drawImageAtChunk(
              position.x,
              position.y,
              element.image,
              connecting_side,
              element.size.width,
              element.size.height,
              () =>
                onChunkDrawn(() => {
                  updateCanvasPreview(rotation);
                }),
            );
          }

          if (text) {
            const connectingSide = (connecting_side + rotation) % 5;
            drawTextAtChunk(position.x, position.y, text, connectingSide, () =>
              onChunkDrawn(() => {
                updateCanvasPreview(rotation);
              }),
            );
          }
        });

        if (setup.length === 0) updateCanvasPreview(rotation);
      };

      // First pass: setup
      const prefixOffset = prefixFormQuestions.length;
      const questionsToProcess =
        formConfig.form?.slice(0, questionIndex - prefixOffset + 1) || [];

      questionsToProcess.forEach((question, i) => {
        const optionName = selections[i + prefixOffset];
        const option = Object.values(question.options).find(
          (opt) => opt.name === optionName,
        );

        if (option?.image && question?.position) {
          totalChunks++;
          selectionPositions.push(question.position);
          drawImageAtChunk(
            question.position.x,
            question.position.y,
            option.image,
            question.connecting_side,
            option.size.width,
            option.size.height,
            () => onChunkDrawn(handleSecondPass),
          );
        }
      });

      // If no selections need to be drawn, move to second pass
      if (completedChunks === totalChunks) {
        handleSecondPass();
      }
    };

    const getCanvasBlob = (type = 'image/jpeg', quality = 0.8) => {
      const canvas = canvasRef.current;
      return new Promise((resolve, reject) => {
        if (!canvas) {
          reject('Failed to create Blob from canvas'); // Reject if canvas is null
        }

        // Use toBlob to create a Blob from the canvas
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob); // Resolve with the Blob
            } else {
              reject('Failed to create Blob from canvas'); // Reject if Blob is null
            }
          },
          type, // MIME type (either image/png or image/jpeg)
          type === 'image/jpeg' ? quality : undefined, // Set quality only for JPEG images
        );
      });
    };

    useEffect(() => {
      const canvas = canvasRef.current;
      if (!offscreenCanvasRef.current) {
        const offscreenCanvas = document.createElement('canvas'); // Create an offscreen canvas
        offscreenCanvasRef.current = offscreenCanvas;
      }

      if (canvas) {
        canvas.width = chunkWidth * chunkSize;
        canvas.height = chunkHeight * chunkSize;
        offscreenCanvasRef.current.width = chunkWidth * chunkSize;
        offscreenCanvasRef.current.height = chunkHeight * chunkSize;
      }

      drawGrid();
      updateCanvasPreview();
    }, [drawGrid, chunkWidth, chunkHeight, chunkSize]);

    useImperativeHandle(ref, () => ({
      clearImage,
      drawGrid,
      updateCanvasPreview,
      updatePreviewSelections,
      getCanvasBlob,
    }));

    return <canvas ref={canvasRef} />;
  },
);

export default ImageCanvas;