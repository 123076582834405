import React, { forwardRef } from 'react';
import ImageCanvas from './ImageCanvas';
import { FaArrowsAltV, FaArrowsAltH } from 'react-icons/fa';
import { FaArrowRotateRight } from 'react-icons/fa6';
import { MdOutlineAttachMoney } from "react-icons/md";
import { formatCurrency, getCSSVariable, pulseOnce } from '../utils/Utils';
import './../styles/FormPreview.scss';

const FormPreview = forwardRef((props, ref) => {
  const color = getCSSVariable('--icon');

  const handleRotateClicked = () => {
    pulseOnce('#rotate-button', () => {
      props.onRotate && props.onRotate();
    });
  };

  return (
    <div className="form-preview">
      <div className="image-container">
        <div
          className="rotate-icon"
          type="button"
          id="rotate-button"
          onClick={handleRotateClicked}
        >
          <FaArrowRotateRight size={32} color={color} />
        </div>
        <ImageCanvas
          ref={ref}
          chunkWidth={props.chunkWidth || 5}
          chunkHeight={props.chunkHeight || 5}
          chunkSize={100}
        />
      </div>
      <div className="info-container">
        <div className="dimensions-container">
          <div className="width-container">
            <FaArrowsAltH className="icon" size={32} color={color} />
            <h4 className="info-title">Width:</h4>
            <h4 className="info-body">{`${props.width || 0} ft`}</h4>
          </div>
          <div className="height-container">
            <FaArrowsAltV className="icon" size={28} color={color} />
            <h4 className="info-title">Length:</h4>
            <h4 className="info-body">{`${props.height || 0} ft`}</h4>
          </div>
        </div>
        <div className="cost-container">
          <MdOutlineAttachMoney className="icon" size={32} color={color} />
          <h4 className="info-title">Cost:</h4>
          <h4 className="info-body">{formatCurrency(props.cost || 0)}</h4>
        </div>
      </div>
    </div>
  );
});

export default FormPreview;