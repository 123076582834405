// 'Height of Towers' -> Selected 5ft

import { elements } from '../../../constants/elements';

export const form = {
  details: {
    gridSize: { width: 5, height: 5 },
  },
  setup: [
    {
      element: elements.tower['5ft'].tower_five_foot,
      position: { x: 3, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'A',
      position: { x: 3, y: 2 },
      connecting_side: 1,
    },
    {
      text: 'B',
      position: { x: 4, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'C',
      position: { x: 3, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'D',
      position: { x: 2, y: 3 },
      connecting_side: 1,
    },
  ],
};
