// 'Half Tower Layout' -> Selected Side A

import { elements } from '../../../constants/elements';

export const form = {
  details: {
    gridSize: { width: 6, height: 6 },
  },
  setup: [
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 3, y: 4 },
      connecting_side: 1,
    },
    {
      element: elements.tower['7ft'].tower_seven_foot,
      position: { x: 4, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'A',
      position: { x: 3, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'C',
      position: { x: 3, y: 5 },
      connecting_side: 1,
    },
    {
      text: 'D',
      position: { x: 2, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'E',
      position: { x: 4, y: 3 },
      connecting_side: 1,
    },
    {
      text: 'F',
      position: { x: 5, y: 4 },
      connecting_side: 1,
    },
    {
      text: 'G',
      position: { x: 4, y: 5 },
      connecting_side: 1,
    },
  ],
  form: [
    {
      question: 'Side A',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: { x: 3, y: 3 },
      connecting_side: 1,
    },
    {
      question: 'Side C',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [],
      position: { x: 3, y: 5 },
      connecting_side: 3,
    },
    {
      question: 'Side D',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [],
      position: { x: 2, y: 4 },
      connecting_side: 2,
    },

    {
      question: 'Side E',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
        elements.attachment['5ft'].rock_wall,
        elements.attachment['5ft'].rope_ladder,
        elements.attachment['5ft'].vinyl_ramp,
        elements.attachment['7ft'].rock_wall,
        elements.attachment['7ft'].rope_ladder,
      ],
      position: { x: 4, y: 3 },
      connecting_side: 1,
    },
    {
      question: 'Side F',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment.universal.swings,
        ...elements.attachment['7ft'],
      },
      exclude_options: [],
      position: { x: 5, y: 4 },
      connecting_side: 4,
    },
    {
      question: 'Side G',
      options: {
        ...elements.attachment.universal.default,
        ...elements.attachment['7ft'],
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
        elements.attachment['5ft'].rock_wall,
        elements.attachment['5ft'].rope_ladder,
        elements.attachment['5ft'].vinyl_ramp,
        elements.attachment['7ft'].rock_wall,
        elements.attachment['7ft'].rope_ladder,
      ],
      position: { x: 4, y: 5 },
      connecting_side: 3,
    },
    {
      question: 'Choose Roof Type',
      options: { ...elements.roofs['1 Tower'] },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Tower Add-Ons',
      options: { ...elements.add_ons['7ft'], ...elements.add_ons.universal },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Include Mist System?',
      options: elements.mist_system.one_tower,
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Accent Color',
      options: {
        ...elements.accent_colors.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (1 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (2 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Swing (3 of 3)',
      options: {
        ...elements.swings.universal,
      },
      exclude_options: [],
      position: null,
    },
    {
      question: 'Choose Tower 1 Base',
      options: {
        ...elements.tower_base['7ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },
    {
      question: 'Choose Half Tower Base',
      options: {
        ...elements.tower_base['7ft'],
        ...elements.tower_base.universal,
      },
      exclude_options: [
        elements.attachment['5ft'].tube_slide,
        elements.attachment['7ft'].tube_slide,
      ],
      position: null,
    },
  ],
};
