// <--- PREFIXES ---> \\
import { form as prefix_1_0_0_0 } from '../assets/froms/prefix/prefix_1_0_0_0.js';
import { form as prefix_1_1_0_0 } from '../assets/froms/prefix/prefix_1_1_0_0.js';
import { form as prefix_1_1_1_0 } from '../assets/froms/prefix/prefix_1_1_1_0.js';
import { form as prefix_1_1_1_1 } from '../assets/froms/prefix/prefix_1_1_1_1.js';
import { form as prefix_1_1_2_0 } from '../assets/froms/prefix/prefix_1_1_2_0.js';
import { form as prefix_1_1_2_1 } from '../assets/froms/prefix/prefix_1_1_2_1.js';
import { form as prefix_1_2_0_0 } from '../assets/froms/prefix/prefix_1_2_0_0.js';
import { form as prefix_1_2_1_0 } from '../assets/froms/prefix/prefix_1_2_1_0.js';
import { form as prefix_1_2_1_1 } from '../assets/froms/prefix/prefix_1_2_1_1.js';
import { form as prefix_1_2_2_0 } from '../assets/froms/prefix/prefix_1_2_2_0.js';
import { form as prefix_1_2_2_1 } from '../assets/froms/prefix/prefix_1_2_2_1.js';
import { form as prefix_1_2_3_0 } from '../assets/froms/prefix/prefix_1_2_3_0.js';
import { form as prefix_1_2_3_1 } from '../assets/froms/prefix/prefix_1_2_3_1.js';
import { form as prefix_1_3_0_0 } from '../assets/froms/prefix/prefix_1_3_0_0.js';
import { form as prefix_1_3_1_0 } from '../assets/froms/prefix/prefix_1_3_1_0.js';
import { form as prefix_1_3_1_1 } from '../assets/froms/prefix/prefix_1_3_1_1.js';
import { form as prefix_1_3_1_2 } from '../assets/froms/prefix/prefix_1_3_1_2.js';
import { form as prefix_1_3_1_3 } from '../assets/froms/prefix/prefix_1_3_1_3.js';
import { form as prefix_1_3_2_0 } from '../assets/froms/prefix/prefix_1_3_2_0.js';
import { form as prefix_1_3_2_1 } from '../assets/froms/prefix/prefix_1_3_2_1.js';
import { form as prefix_1_3_2_2 } from '../assets/froms/prefix/prefix_1_3_2_2.js';
import { form as prefix_1_3_2_3 } from '../assets/froms/prefix/prefix_1_3_2_3.js';

// <--- INFIXES ---> \\
// <--- ONE TOWER ---> \\

// 5ft
import { form as form_1_1_1 } from '../assets/froms/infix/form_1_1_1.js';
// 5ft + Half
import { form as form_1_1_2 } from '../assets/froms/infix/form_1_1_2.js';
// 7ft
import { form as form_1_2_1 } from '../assets/froms/infix/form_1_2_1.js';
// 7ft + Half
import { form as form_1_2_2 } from '../assets/froms/infix/form_1_2_2.js';

// <--- TWO TOWERS ---> \\

// 5ft + 5ft
import { form as form_2_1_1 } from '../assets/froms/infix/form_2_1_1.js';
// 5ft + 5ft + Half - Tower 2 Side A
import { form as form_2_1_2 } from '../assets/froms/infix/form_2_1_2.js';
// 5ft + 5ft + Half - Tower 2 Side B
import { form as form_2_1_3 } from '../assets/froms/infix/form_2_1_3.js';
// 5ft + 5ft + Half - Tower 2 Side C
import { form as form_2_1_4 } from '../assets/froms/infix/form_2_1_4.js';

// 5ft + 7ft
import { form as form_2_2_1 } from '../assets/froms/infix/form_2_2_1.js';
// 5ft + 7ft + Half - Tower 2 Side A (7ft)
import { form as form_2_2_2 } from '../assets/froms/infix/form_2_2_2.js';
// 5ft + 7ft + Half - Tower 2 Side B (7ft)
import { form as form_2_2_3 } from '../assets/froms/infix/form_2_2_3.js';
// 5ft + 7ft + Half - Tower 2 Side C (7ft)
import { form as form_2_2_4 } from '../assets/froms/infix/form_2_2_4.js';
// 5ft + 7ft + Half - Tower 1 Side A (5ft)
import { form as form_2_2_5 } from '../assets/froms/infix/form_2_2_5.js';
// 5ft + 7ft + Half - Tower 1 Side C (5ft)
import { form as form_2_2_6 } from '../assets/froms/infix/form_2_2_6.js';
// 5ft + 7ft + Half - Tower 1 Side D (5ft)
import { form as form_2_2_7 } from '../assets/froms/infix/form_2_2_7.js';

// 7ft + 7ft
import { form as form_2_3_1 } from '../assets/froms/infix/form_2_3_1.js';
// 7ft + 7ft + Half - Tower 2 Side A
import { form as form_2_3_2 } from '../assets/froms/infix/form_2_3_2.js';
// 7ft + 7ft + Half - Tower 2 Side B
import { form as form_2_3_3 } from '../assets/froms/infix/form_2_3_3.js';
// 7ft + 7ft + Half - Tower 2 Side C
import { form as form_2_3_4 } from '../assets/froms/infix/form_2_3_4.js';

// <--- THREE TOWERS ---> \\

// 5ft + 5ft + 5ft (Straight)
import { form as form_3_1_1 } from '../assets/froms/infix/form_3_1_1.js';
// 5ft + 5ft + 5ft + Half - Tower 3 Side A
import { form as form_3_1_2 } from '../assets/froms/infix/form_3_1_2.js';
// 5ft + 5ft + 5ft + Half - Tower 3 Side B
import { form as form_3_1_3 } from '../assets/froms/infix/form_3_1_3.js';
// 5ft + 5ft + 5ft + Half - Tower 3 Side C
import { form as form_3_1_4 } from '../assets/froms/infix/form_3_1_4.js';
// 5ft + 5ft + 5ft + Half - Tower 2 Side A
import { form as form_3_1_5 } from '../assets/froms/infix/form_3_1_5.js';

// 5ft + 5ft + 5ft (Right Elbow)
import { form as form_3_2_1 } from '../assets/froms/infix/form_3_2_1.js';
// 5ft + 5ft + 5ft (Right Elbow) + Half - Tower 3 Side A
import { form as form_3_2_2 } from '../assets/froms/infix/form_3_2_2.js';
// 5ft + 5ft + 5ft (Right Elbow) + Half - Tower 3 Side B
import { form as form_3_2_3 } from '../assets/froms/infix/form_3_2_3.js';
// 5ft + 5ft + 5ft (Right Elbow) + Half - Tower 3 Side C
import { form as form_3_2_4 } from '../assets/froms/infix/form_3_2_4.js';
// 5ft + 5ft + 5ft (Right Elbow) + Half - Tower 2 Side A
import { form as form_3_2_5 } from '../assets/froms/infix/form_3_2_5.js';
// 5ft + 5ft + 5ft (Right Elbow) + Half - Tower 2 Side D
import { form as form_3_2_6 } from '../assets/froms/infix/form_3_2_6.js';
// 5ft + 5ft + 5ft (Right Elbow) + Half - Tower 1 Side B
import { form as form_3_2_7 } from '../assets/froms/infix/form_3_2_7.js';
// 5ft + 5ft + 5ft (Right Elbow) + Half - Tower 1 Side C
import { form as form_3_2_8 } from '../assets/froms/infix/form_3_2_8.js';
// 5ft + 5ft + 5ft (Right Elbow) + Half - Tower 1 Side D
import { form as form_3_2_9 } from '../assets/froms/infix/form_3_2_9.js';

// 5ft + 5ft + 5ft (Left Elbow)
import { form as form_3_3_1 } from '../assets/froms/infix/form_3_3_1.js';
// 5ft + 5ft + 5ft (Left Elbow) + Half - Tower 3 Side A
import { form as form_3_3_2 } from '../assets/froms/infix/form_3_3_2.js';
// 5ft + 5ft + 5ft (Left Elbow) + Half - Tower 3 Side C
import { form as form_3_3_3 } from '../assets/froms/infix/form_3_3_3.js';
// 5ft + 5ft + 5ft (Left Elbow) + Half - Tower 3 Side D
import { form as form_3_3_4 } from '../assets/froms/infix/form_3_3_4.js';
// 5ft + 5ft + 5ft (Left Elbow) + Half - Tower 2 Side A
import { form as form_3_3_5 } from '../assets/froms/infix/form_3_3_5.js';
// 5ft + 5ft + 5ft (Left Elbow) + Half - Tower 2 Side B
import { form as form_3_3_6 } from '../assets/froms/infix/form_3_3_6.js';
// 5ft + 5ft + 5ft (Left Elbow) + Half - Tower 1 Side B
import { form as form_3_3_7 } from '../assets/froms/infix/form_3_3_7.js';
// 5ft + 5ft + 5ft (Left Elbow) + Half - Tower 1 Side C
import { form as form_3_3_8 } from '../assets/froms/infix/form_3_3_8.js';
// 5ft + 5ft + 5ft (Left Elbow) + Half - Tower 1 Side D
import { form as form_3_3_9 } from '../assets/froms/infix/form_3_3_9.js';

// 7ft + 7ft + 7ft (Straight)
import { form as form_3_4_1 } from '../assets/froms/infix/form_3_4_1.js';
// 7ft + 7ft + 7ft + Half - Tower 3 Side A
import { form as form_3_4_2 } from '../assets/froms/infix/form_3_4_2.js';
// 7ft + 7ft + 7ft + Half - Tower 3 Side B
import { form as form_3_4_3 } from '../assets/froms/infix/form_3_4_3.js';
// 7ft + 7ft + 7ft + Half - Tower 3 Side C
import { form as form_3_4_4 } from '../assets/froms/infix/form_3_4_4.js';
// 7ft + 7ft + 7ft + Half - Tower 2 Side A
import { form as form_3_4_5 } from '../assets/froms/infix/form_3_4_5.js';

// 7ft + 7ft + 7ft (Right Elbow)
import { form as form_3_5_1 } from '../assets/froms/infix/form_3_5_1.js';
// 7ft + 7ft + 7ft (Right Elbow) + Half - Tower 3 Side A
import { form as form_3_5_2 } from '../assets/froms/infix/form_3_5_2.js';
// 7ft + 7ft + 7ft (Right Elbow) + Half - Tower 3 Side B
import { form as form_3_5_3 } from '../assets/froms/infix/form_3_5_3.js';
// 7ft + 7ft + 7ft (Right Elbow) + Half - Tower 3 Side C
import { form as form_3_5_4 } from '../assets/froms/infix/form_3_5_4.js';
// 7ft + 7ft + 7ft (Right Elbow) + Half - Tower 2 Side A
import { form as form_3_5_5 } from '../assets/froms/infix/form_3_5_5.js';
// 7ft + 7ft + 7ft (Right Elbow) + Half - Tower 2 Side D
import { form as form_3_5_6 } from '../assets/froms/infix/form_3_5_6.js';
// 7ft + 7ft + 7ft (Right Elbow) + Half - Tower 1 Side B
import { form as form_3_5_7 } from '../assets/froms/infix/form_3_5_7.js';
// 7ft + 7ft + 7ft (Right Elbow) + Half - Tower 1 Side C
import { form as form_3_5_8 } from '../assets/froms/infix/form_3_5_8.js';
// 7ft + 7ft + 7ft (Right Elbow) + Half - Tower 1 Side D
import { form as form_3_5_9 } from '../assets/froms/infix/form_3_5_9.js';

// 7ft + 7ft + 7ft (Left Elbow)
import { form as form_3_6_1 } from '../assets/froms/infix/form_3_6_1.js';
// 7ft + 7ft + 7ft (Left Elbow) + Half - Tower 3 Side A
import { form as form_3_6_2 } from '../assets/froms/infix/form_3_6_2.js';
// 7ft + 7ft + 7ft (Left Elbow) + Half - Tower 3 Side C
import { form as form_3_6_3 } from '../assets/froms/infix/form_3_6_3.js';
// 7ft + 7ft + 7ft (Left Elbow) + Half - Tower 3 Side D
import { form as form_3_6_4 } from '../assets/froms/infix/form_3_6_4.js';
// 7ft + 7ft + 7ft (Left Elbow) + Half - Tower 2 Side A
import { form as form_3_6_5 } from '../assets/froms/infix/form_3_6_5.js';
// 7ft + 7ft + 7ft (Left Elbow) + Half - Tower 2 Side B
import { form as form_3_6_6 } from '../assets/froms/infix/form_3_6_6.js';
// 7ft + 7ft + 7ft (Left Elbow) + Half - Tower 1 Side B
import { form as form_3_6_7 } from '../assets/froms/infix/form_3_6_7.js';
// 7ft + 7ft + 7ft (Left Elbow) + Half - Tower 1 Side C
import { form as form_3_6_8 } from '../assets/froms/infix/form_3_6_8.js';
// 7ft + 7ft + 7ft (Left Elbow) + Half - Tower 1 Side D
import { form as form_3_6_9 } from '../assets/froms/infix/form_3_6_9.js';

export const prefixFormQuestions = [
  'Number of Towers',
  'Height of Towers',
  'Layout of Towers',
  'Half Tower Layout',
];

export const formConfigs = {
  form: prefix_1_0_0_0, // 'Number of Towers' -> No Selection
  options: {
    '1/1.5 Towers': {
      form: prefix_1_1_0_0, // 'Number of Towers' -> Selected 1 Tower ('Height of Towers' -> No Selection)
      options: {
        '5 ft (1 Tower)': {
          form: prefix_1_1_1_0, // 'Height of Towers' -> Selected 5ft
          options: {
            Single: {
              form: prefix_1_1_1_0, // 'Half Tower Layout' -> No Selection
              options: {
                None: form_1_1_1, // 'Half Tower Layout' -> Selected None
              },
            },
          },
        },
        '7 ft (1 Tower)': {
          form: prefix_1_1_2_0,
          options: {
            Single: {
              form: prefix_1_1_2_0,
              options: {
                None: form_1_2_1,
              },
            },
          },
        },
        '7 ft (1.5 Towers)': {
          form: prefix_1_1_2_1,
          options: {
            Single: {
              form: prefix_1_1_2_1,
              options: {
                'Side A': form_1_2_2,
              },
            },
          },
        },
        '7 ft (Double Tower)': {
          form: prefix_1_1_1_1,
          options: {
            Single: {
              form: prefix_1_1_1_1,
              options: {
                'Side A': form_1_1_2,
              },
            },
          },
        },
      },
    },
    '2 Towers': {
      form: prefix_1_2_0_0, // 'Number of Towers' -> Selected 2 Towers ('Height of Towers' -> No Selection)
      options: {
        '5ft': {
          form: prefix_1_2_1_0,
          options: {
            Straight: {
              form: prefix_1_2_1_1,
              options: {
                None: form_2_1_1,
                'Tower 1 Side A': form_2_1_2,
                'Tower 1 Side D': form_2_1_3,
                'Tower 1 Side C': form_2_1_4,
              },
            },
          },
        },
        '5ft & 7ft': {
          form: prefix_1_2_2_0,
          options: {
            Straight: {
              form: prefix_1_2_2_1,
              options: {
                None: form_2_2_1,
                'Side A (5ft)': form_2_2_2,
                'Side D (5ft)': form_2_2_3,
                'Side C (5ft)': form_2_2_4,
                'Side E (7ft)': form_2_2_5,
                'Side F (7ft)': form_2_2_6,
                'Side G (7ft)': form_2_2_7,
              },
            },
          },
        },
        '7ft': {
          form: prefix_1_2_3_0,
          options: {
            Straight: {
              form: prefix_1_2_3_1,
              options: {
                None: form_2_3_1,
                'Side A': form_2_3_2,
                'Side D': form_2_3_3,
                'Side C': form_2_3_4,
              },
            },
          },
        },
      },
    },
    '3 Towers': {
      form: prefix_1_3_0_0, // 'Number of Towers' -> Selected 3 Towers ('Height of Towers' -> No Selection)
      options: {
        '5ft': {
          form: prefix_1_3_1_0,
          options: {
            Straight: {
              form: prefix_1_3_1_1,
              options: {
                None: form_3_1_1,
                'Side A': form_3_1_2,
                'Side H': form_3_1_3,
                'Side G': form_3_1_4,
                'Side B': form_3_1_5,
              },
            },
            'Right Elbow': {
              form: prefix_1_3_1_2,
              options: {
                None: form_3_2_1,
                'Tower 1 Side A': form_3_2_2,
                'Tower 1 Side B': form_3_2_3,
                'Tower 1 Side C': form_3_2_4,
                'Tower 2 Side E': form_3_2_5,
                'Tower 2 Side F': form_3_2_6,
                'Tower 3 Side H': form_3_2_7,
                'Tower 3 Side I': form_3_2_8,
                'Tower 3 Side J': form_3_2_9,
              },
            },
            'Left Elbow': {
              form: prefix_1_3_1_3,
              options: {
                None: form_3_3_1,
                'Tower 1 Side A': form_3_3_2,
                'Tower 1 Side B': form_3_3_3,
                'Tower 1 Side C': form_3_3_4,
                'Tower 2 Side E': form_3_3_5,
                'Tower 2 Side F': form_3_3_6,
                'Tower 3 Side H': form_3_3_7,
                'Tower 3 Side I': form_3_3_8,
                'Tower 3 Side J': form_3_3_9,
              },
            },
          },
        },
        '7ft': {
          form: prefix_1_3_2_0,
          options: {
            Straight: {
              form: prefix_1_3_2_1,
              options: {
                None: form_3_4_1,
                'Side A': form_3_4_2,
                'Side H': form_3_4_3,
                'Side G': form_3_4_4,
                'Side B': form_3_4_5,
              },
            },
            'Right Elbow': {
              form: prefix_1_3_2_2,
              options: {
                None: form_3_5_1,
                'Tower 1 Side A': form_3_5_2,
                'Tower 1 Side B': form_3_5_3,
                'Tower 1 Side C': form_3_5_4,
                'Tower 2 Side E': form_3_5_5,
                'Tower 2 Side F': form_3_5_6,
                'Tower 3 Side H': form_3_5_7,
                'Tower 3 Side I': form_3_5_8,
                'Tower 3 Side J': form_3_5_9,
              },
            },
            'Left Elbow': {
              form: prefix_1_3_2_3,
              options: {
                None: form_3_6_1,
                'Tower 1 Side A': form_3_6_2,
                'Tower 1 Side B': form_3_6_3,
                'Tower 1 Side C': form_3_6_4,
                'Tower 2 Side E': form_3_6_5,
                'Tower 2 Side F': form_3_6_6,
                'Tower 3 Side H': form_3_6_7,
                'Tower 3 Side I': form_3_6_8,
                'Tower 3 Side J': form_3_6_9,
              },
            },
          },
        },
      },
    },
  },
};

export const InputType = Object.freeze({
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS: 'address',
});
