import React, { useEffect, useRef, useState } from 'react';
import './../styles/FormContent.scss';
import { getCSSVariable, pulseOnce, shake } from '../utils/Utils';
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from 'react-icons/bs';

const FormContent = ({
  question,
  options,
  selection,
  onPrevious,
  onNext,
  onOptionClick,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    options.indexOf(selection),
  );
  const selectedIndexRef = useRef(selectedIndex);
  const optionsRef = useRef(null);
  const color = getCSSVariable('--icon');

  useEffect(() => {
    const width = getCSSVariable('--phone-width')
    const mediaQuery = window.matchMedia(`(max-width: ${width})`); // Your mobile screen width
    const handleResize = () => setIsMobile(mediaQuery.matches);

    // Initial check to set the state based on the current window size
    handleResize();

    // Add event listener for screen size changes
    mediaQuery.addEventListener('change', handleResize);

    // Cleanup the listener when the component unmounts or on re-render
    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []); // Empty dependency array ensures this only runs once when the component mounts

  useEffect(() => {
    selectedIndexRef.current = selectedIndex;
  }, [selectedIndex]);

  useEffect(() => {
    if (optionsRef.current) {
      const element = document.getElementById(
        `option-${selectedIndexRef.current}`,
      );
      if (element) {
        // Scroll to the user's selection
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        // Scroll to top on question change
        optionsRef.current.scrollTop = 0;
      }
    }
  }, [question]);

  // Handle the option click
  const handleOptionClick = (option, index) => {
    setSelectedIndex(index);
    onOptionClick(option, index); // Call the callback for option selection
    pulseOnce(`#option-${index}`);
  };

  const handlePreviousClick = () => {
    onPrevious?.();
  };

  const handleNextClick = () => {
    pulseOnce('#next-button', () => {
      onNext?.();
    });
  };

  const disabledClick = () => {
    shake('#next-button', () => {});
  };

  const renderNavigation = () => {
    return (
      <div className="form-navigation">
        <div
          className="left"
          type="button"
          id="previous-button"
          onClick={handlePreviousClick}
        >
          <BsArrowLeftSquareFill size={32} color={color} />
        </div>
        {isMobile && <h2 className="question">{question}</h2>}
        <div
          className={`right ${selectedIndex > -1 ? 'disabled' : ''}`}
          type="button"
          id="next-button"
          onClick={selectedIndex > -1 ? handleNextClick : disabledClick}
        >
          <BsArrowRightSquareFill size={32} color={color} />
        </div>
      </div>
    );
  };

  return (
    <div className="form-content">
      <div className="form-content-container">
        {isMobile && renderNavigation()}
        {!isMobile && <h2 className="question">{question}</h2>}
        <div className="options" ref={optionsRef}>
          {options.map((option, index) => (
            <div
              key={index}
              className={`option ${selectedIndex === index ? 'selected' : ''}`}
              id={`option-${index}`}
              onClick={() => handleOptionClick(option, index)}
            >
              {option}
            </div>
          ))}
        </div>
        {!isMobile && renderNavigation()}
      </div>
    </div>
  );
};

export default FormContent;