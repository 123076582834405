const pulseOnce = (selector, callback) => {
  const element = document.querySelector(selector);
  if (element) {
    element.classList.add('pulse-once');
    setTimeout(() => {
      element.classList.remove('pulse-once');
      if (callback) callback();
    }, 200);
  }
};

const shake = (selector, callback) => {
  const element = document.querySelector(selector);
  if (element) {
    element.classList.add('shake');
    setTimeout(() => {
      element.classList.remove('shake');
      if (callback) callback();
    }, 250);
  }
};

// Get a CSS variable value
const getCSSVariable = (variableName) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim();
};

const formatCurrency = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
};

export { pulseOnce, shake, getCSSVariable, formatCurrency };
